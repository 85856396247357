.job-contents__inner .bgt-box__image-container {
  img {
    width:100%;
  }
}
.main-contents, .top__body, .article__body, .job-contents__inner{
  .bgt-btn {
    padding:10px 20px;
  }
  .bgt-grid4 {
    width: 45%;
  }
  // line-height: 30px;
  font-size: 16px;
  table {
    td, th {
      line-height: 24px;
    }
    td {
      vertical-align: top;
    }
  }
  img {
    @include rwd_img;
  }
  h2 {
    position: relative;
    margin-bottom: 1em;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.41;
    padding-bottom: 0.5em;
    border-bottom: 5px solid rgba($main_color, .1);
    @include mq(sp) {
      font-size: 18px;
    }
    &:before {
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 50px;
      height: 5px;
      background: $main_color;
      content: "";
    }
  }
  h3 {
    margin-bottom: 1em;
    padding-left: 12px;
    border-left: 3px solid $main_color;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.41;
    @include mq(sp) {
      font-size: 16px;
    }
  }
  h4 {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.41;
    @include mq(sp) {
      font-size: 14px;
    }
  }
  p {
    margin-bottom: 1.5em;
    font-size: 15px;
    line-height: 1.7;
    @include mq(sp) {
      font-size: 13px;
      line-height: 1.7;
    }
    a {
      color: $main_color;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  ul {
    margin-bottom: 1.5em;
    li {
      position: relative;
      line-height: 2;
      margin-bottom: 0.5em;
      padding-left: 14px;
      @include mq(sp) {
        line-height: 1.7;
        font-size: 13px;
      }
      &:before {
        position: absolute;
        top: 10px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba($main_color, .3);
        content: "";
        @include mq(sp) {
          top: 7px;
        }
      }
    }
  }
  ol {
    margin-left: 20px;
    margin-bottom: 1.5em;
    li {
      position: relative;
      line-height: 2;
      margin-bottom: 0.5em;
      padding-left: 14px;
      list-style: decimal!important;
      list-style-position: inside;
      @include mq(sp) {
        line-height: 1.7;
        font-size: 13px;
      }
    }
  }
  table {
    width: 100%;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
    @include mq(sp) {
      float: none;
      font-size: 12px;
    }

    th {
      padding: 12px 15px;
      box-sizing: border-box;
      font-weight: bold;
      width: 120px;
      border-bottom: 1px solid #ddd;
      background-color: #f0f0f0;
      vertical-align: top;
      @include mq(sp) {
        width: 100px;
      }
    }
    td {
      padding: 12px 20px;
      border-bottom: 1px solid #ddd;
      background: #fff;
    }
  }

}
.job-contents {
  h2 {
    margin-bottom: 1em;
    font-size: 26px;
    font-weight: bold;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  p {
    strong {
      display: inline;
      background: linear-gradient(transparent 70%, $main_color 70%);
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .bgb-text-image1,
  .bgb-text-image2,
  .bgb-wysiwyg {
    margin-bottom: 50px;
  }
  .bgt-image-container {
    @include mq(sp) {
      margin-bottom: 1em;
    }
  }
  table {
    width: 100%;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
    @include mq(sp) {
      float: none;
      font-size: 12px;
    }

    th {
      padding: 12px 15px;
      box-sizing: border-box;
      font-weight: bold;
      width: 230px;
      border-bottom: 1px solid #ddd;
      background-color: #f0f0f0;
      vertical-align: top;
      @include mq(sp) {
        width: 100px;
      }
    }
    td {
      padding: 12px 20px;
      border-bottom: 1px solid #ddd;
      background: #fff;
    }
  }
  .m-scroll {
    &:hover {
      text-decoration: none;
    }
  }
}

[data-bgb="rm-company"] {
  .bgt-grid--first {
    float: left;
    img {
      margin-bottom: 20px;
      @include mq(sp) {
        width: 100%;
      }
    }
    @include mq(sp) {
      margin: 0;
      width: 100%;
      float: none;
      margin-bottom: 20px;
      font-size: 12px;
    }
  }
  .bgt-grid--last {
    float: right;
  }
  .bgt-grid12 {
    margin-bottom: 20px;
  }
  table {
    width: 100%;
    border-top: 1px solid #ddd;
    @include mq(sp) {
      margin: 0;
      width: 100%;
      float: none;
      margin-bottom: 20px;
      font-size: 12px;
    }
    th {
      padding: 12px 15px;
      box-sizing: border-box;
      font-weight: bold;
      width: 120px;
      border-bottom: 1px solid #ddd;
      background-color: #f0f0f0;
      vertical-align: top;
      @include mq(sp) {
        width: 100px;
      }
    }
    td {
      padding: 12px 20px;
      border-bottom: 1px solid #ddd;
      background: #fff;
    }
    caption, th, td {
      text-align: left;
      font-weight: normal;
      vertical-align: middle;
    }
  }
  .loop-slide{
    ul {
      li {
        padding-left:0;
         &:before {
           width:0;
           height:0;
         }
      }
    }
  }
}

[data-bgb="rm-philosophy1"] {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #FFF;
  .rm-body {
    p {
      line-height: 2.375;
      margin-bottom: 30px;
      text-align: left;
    }
  }
  @include mq(pc) {
    @include medium-range-full-width;
  }
  @include mq(sp) {
    width: 100%;
    position: relative;
  }
}


[data-bgb="rm-philosophy1"] {
  padding-top: 60px;
  font-weight: bold;
  padding-bottom:60px;
  .rm-head1 {
    text-align: center;
    h2 {
      position: relative;
      display: inline-block;
      font-size: 26px;
      color: $txt_color;
      line-height: 1 !important;
      margin-bottom: 40px;
      padding-bottom: 0;
      border: 0;
      position: inherit;
      background: linear-gradient(transparent 70%, $main_color 70%);
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background: none;
        content: "";
      }
      @include mq(sp) {
        margin-bottom: 15px;
        font-size: 18px;
      }
    }
  }
}

[data-bgb="rm-philosophy2"] {
  padding-top: 55px;
  @include mq(sp) {
    padding-top: 40px;
  }
  .rm-body {
    position: relative;
    width: 50%;
    @include mq(sp) {
      padding-top: 0;
      width: 100%;
    }
  }
  .bgt-title-h3-container {
    max-width: 600px;
  }
  .bge-ckeditor {
    max-width: 600px;
  }
  .right-image {
    overflow: hidden;
    margin: 0 auto 60px;
    width:100%;
    position: relative;
    @include mq(sp) {
      text-align: center;
    }
    .rm-body {
      position: relative;
      left: 55px;
      @include mq(sp) {
        left: 0;
      }
    }
    .bgt-image-container {
      padding-left: 100px;
      @include mq(sp) {
        padding-left: 0;
      }
    }
    .bgt-title-h3-container {
      margin: 0 0 15px auto;
    }
    .bge-ckeditor {
      margin: 0 0 0 auto;
    }
  }
  .left-image {
    overflow: hidden;
    margin: 0 auto 60px;
    width:100%;
    position: relative;
    @include mq(sp) {
      display: flex;
      flex-wrap:wrap;
      text-align: center;
    }
    .rm-body {
      position: relative;
      left: -55px;
      @include mq(sp) {
        left: 0;
      }
    }
    .bgt-image-container {
      padding-right: 100px;
      @include mq(sp) {
        padding: 0;
      }
    }
    .bgt-title-h3-container {
      margin: 0 auto 15px 0;
    }
    .bge-ckeditor {
      margin: 0 auto 0 0;
    }
    .bgt-grid--first {
      @include mq(sp) {
        order: 2;
      }
    }
    .bgt-grid--last {
      @include mq(sp) {
        order: 1;
      }
    }
  }
  .bge-title-h3 {
    display: inline;
    background: linear-gradient(transparent 70%, $main_color 70%);
    font-size: 26px;
    font-weight: bold;
    line-height: 1.2;
    @include mq(sp) {
      font-size: 18px;
    }
  }
  .bge-ckeditor {
    line-height: 1.8;
    @include mq(sp) {
      text-align: left;
    }
    p {
      margin-bottom: 2em;
      @include mq(sp) {
        margin-bottom: 1em;
      }
    }
    a {
      position: relative;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.05em;
      padding-left: 30px;
      @include mq(sp) {
        font-size: 14px;
        display: block;
        padding: 5px 0;
        width: 210px;
        margin: 30px auto 30px;
        border: 3px solid $txt_color;
        text-align: center;
        &:hover {
          text-decoration: none;
        }
      }
      &:before {
        position: absolute;
        top: -2px;
        left: 0;
        width: 24px;
        height: 24px;
        background: $main_color;
        content: "";
        border-radius: 50%;
        @include mq(sp) {
          display: none;
        }
      }
      &:after {
        position: absolute;
        top: -6px;
        left: 10px;
        color: #fff;
        font-family: fontAwesome;
        content: '\f0da';
        font-size: 18px;
        @include mq(sp) {
          display: none;
        }
      }
    }
  }
  .bgt-grid--first {
    float: left;
    clear:both;
    @media screen and (max-width: 768px) {
        float: none;
        padding-right: 0;
    }
    img {
      max-width: inherit;
      width: 100%;
    }
  }

  .bgt-grid--last {
      float: right;
      @media screen and (max-width: 768px) {
        clear: both;
        float: none;
        padding-left: 0;
      }
    .bgt-image-container {
      margin: 0 0 0 auto;
    }
    img {
      max-width: inherit;
      width: 100%;
    }
  }
  .rm-head1 {
    h2 {
      font-family: 'Oswald', sans-serif;
      font-size: 70px;
      letter-spacing: 0.05em;
      text-align: center;
      font-weight: normal;
      line-height: 1;
      margin-bottom: 10px;
      border-bottom: none;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background: none;
        content: "";
      }
      @include mq(sp) {
        font-size: 30px;
      }
    }
    p {
      margin-bottom: 40px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.05em;
      @include mq(sp) {
        margin-bottom: 30px;
        font-size: 12px;
      }
    }
  }
  .rm-head2 {
    a {
      color: $main_color;
      font-size: 30px;
    }
    h3 {
      margin-top: 60px;
      font-size: 30px;
      margin-bottom: 60px;
      font-weight: normal;
      text-align: left;
      @include mq(sp) {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 17px;
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
      }
    }
  }
  .bge-title-h3 {
    border-left:none!important;
  }
}

[data-bgb="rm-philosophy3"] {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 80px;
  @include mq(sp) {
    padding-bottom: 60px;
  }
  .rm-head1 {
    h2 {
      font-family: 'Oswald', sans-serif;
      font-size: 70px;
      letter-spacing: 0.05em;
      text-align: center;
      font-weight: normal;
      line-height: 1;
      margin-bottom: 10px;
      border-bottom: none;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 8px;
        background: none;
        content: "";
      }
      @include mq(sp) {
        font-size: 30px;
      }
    }
    p {
      margin-bottom: 40px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0.05em;
      @include mq(sp) {
        margin-bottom: 20px;
        font-size: 12px;
      }
    }
  }
  .rm-head2 {
    text-align: center;
    margin-top: 40px;
    font-size: 26px;
    margin-bottom: 40px;
    padding-right: 0;
    line-height: 1.3;
    letter-spacing: 0.05em;
    font-weight: bold;
    @include mq(sp) {
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 0;
      line-height: 1.5;
    }
  }
  .rm-body {
    width: 500px;
    margin: 0 auto 50px;
    line-height: 1.85;
    text-align: left;
    font-size: 14px;
    @include mq(sp) {
      width: 100%;
      line-height: 1.8;
      margin: 0 0 20px;
    }
  }
}

[data-bgb="rm-philosophy1"],
[data-bgb="rm-philosophy2"],
[data-bgb="rm-philosophy3"] {
  .bge-ckeditor {
    ul {
      margin-bottom: 1.5em;
      li {
        position: relative;
        line-height: 2;
        margin-bottom: 0.5em;
        padding-left: 14px;
        @include mq(sp) {
          line-height: 1.7;
          font-size: 13px;
        }
        &:before {
          position: absolute;
          top: 10px;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba($main_color, .3);
          content: "";
          @include mq(sp) {
            top: 7px;
          }
        }
      }
    }
    ol {
      margin-bottom: 1.5em;
      li {
        position: relative;
        line-height: 2;
        margin-bottom: 0.5em;
        padding-left: 14px;
        list-style: decimal !important;
        list-style-position: inside;
        @include mq(sp) {
          line-height: 1.7;
          font-size: 13px;
        }
      }
    }
  }
}
.bgt-image[data-bge-hr="false"] .bgt-image__link .bgt-box__image-container img {
	width: 100%;
}



/**
 * ボタン
 *
 */
/* ボタンタイプ */
.bgt-btn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 12px 10px;
  border-width: 1px;
  border-style: solid;
  background: #fff;
  text-decoration: none;
  min-width: 200px;
  border-radius: 2px;
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
          box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}

.bgt-btn--link,
[data-bgt-button-kind="link"] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--link:hover, .bgt-btn--link:active, .bgt-btn--link:focus,
[data-bgt-button-kind="link"] .bgt-btn:hover,
[data-bgt-button-kind="link"] .bgt-btn:active,
[data-bgt-button-kind="link"] .bgt-btn:focus {
  background: #e9e9e9;
}

.bgt-btn--em,
[data-bgt-button-kind="em"] .bgt-btn {
  font-weight: bold;
  color: #fff;
  border-color: #333;
  background: #333;
}

.bgt-btn--em:hover, .bgt-btn--em:active, .bgt-btn--em:focus,
[data-bgt-button-kind="em"] .bgt-btn:hover,
[data-bgt-button-kind="em"] .bgt-btn:active,
[data-bgt-button-kind="em"] .bgt-btn:focus {
  background: #555;
}

.bgt-btn--external,
[data-bgt-button-kind="external"] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--external:hover, .bgt-btn--external:active, .bgt-btn--external:focus,
[data-bgt-button-kind="external"] .bgt-btn:hover,
[data-bgt-button-kind="external"] .bgt-btn:active,
[data-bgt-button-kind="external"] .bgt-btn:focus {
  background: #e9e9e9;
}

.bgt-btn--external::after,
[data-bgt-button-kind="external"] .bgt-btn::after {
  font-family: FontAwesome;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f08e';
  font-size: 1em;
  color: #999;
  vertical-align: middle;
}

.bgt-btn--back,
[data-bgt-button-kind="back"] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

.bgt-btn--back:hover, .bgt-btn--back:active, .bgt-btn--back:focus,
[data-bgt-button-kind="back"] .bgt-btn:hover,
[data-bgt-button-kind="back"] .bgt-btn:active,
[data-bgt-button-kind="back"] .bgt-btn:focus {
  background: #e9e9e9;
}

.bgt-btn--back span,
[data-bgt-button-kind="back"] .bgt-btn span {
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--back::before,
[data-bgt-button-kind="back"] .bgt-btn::before {
  font-family: FontAwesome;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f053';
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}

/* ダウンロードファイルタイプ */
.bgt-download-file__link {
  color: #333;
  text-align: left;
  font-size: 1.08em;
  font-weight: bold;
  display: inline-block;
  padding: 0.7em 1em;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  line-height: 1;
  text-decoration: none;
}

.bgt-download-file__link:hover {
  background-color: #eee;
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
          box-shadow: 0 0 5px 1px #0570c7;
  outline: none;
}

.bgt-link__size {
  color: #808080;
  font-size: 0.8em;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: solid 1px #999;
    border-radius: 3px;
    text-align: center;
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bgt-google-maps-link::after {
    font-family: FontAwesome;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f08e';
    font-size: 1em;
    vertical-align: middle;
  }
}

[data-bgt='gallery'] .bgt-gallery {
  padding-top: 54%;
}

[data-bgt='gallery'] .bgt-gallery:hover [data-bgt='gallery'] .bgt-gallery-ctrl {
  opacity: 1;
}

[data-bgt='gallery'] .bgt-gallery__img img {
  -o-object-fit: cover;
     font-family: "object-fit:cover";
     object-fit: cover;
}

[data-bgt='gallery'] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.7em 1em;
  text-align: center;
}

[data-bgt='gallery'] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev, [data-bgt='gallery'] .bgt-gallery-ctrl__next {
  display: block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev:hover, [data-bgt='gallery'] .bgt-gallery-ctrl__next:hover {
  opacity: 0.5;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev:focus, [data-bgt='gallery'] .bgt-gallery-ctrl__next:focus {
  outline: none;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev span, [data-bgt='gallery'] .bgt-gallery-ctrl__next span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev::after, [data-bgt='gallery'] .bgt-gallery-ctrl__next::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

[data-bgt='gallery'] [data-gallery-marker='thumbs'] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt='gallery'] [data-gallery-marker='thumbs'] + .bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker li.current {
  background-color: #000;
}

/* デバイス依存汎用クラス */
.pc-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

/**
 * 画像タイプのキャプション
 *
 */
.bgt-box__caption {
  text-align: left;
  font-weight: normal;
  padding: 3px;
  background: none;
  font-size: 0.8em;
}
