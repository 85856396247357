//-----------------------------
// 変数宣言用ファイル
//-----------------------------

//-----------------
// メインカラー
//-----------------
$main_color: #ff0066;
//-----------------
// ベーステキストカラー
//-----------------
$txt_color: #000;
//-----------------
// アイコンカラー
//-----------------
$icon_color: #fd6a62;
//-----------------
// 背景カラー
//-----------------
$bg_color: #fff;
//-----------------
// コンテンツ幅
//-----------------
$container: 1000px;
//-----------------
// 黄金比
//-----------------
$golden-ratio: 1.6180339887498948482;
