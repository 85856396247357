@charset "UTF-8";
/*clear fix*/
.job-contents__inner .bgt-box__image-container img {
  width: 100%;
}

.main-contents, .top__body, .article__body, .job-contents__inner {
  font-size: 16px;
}

.main-contents .bgt-btn, .top__body .bgt-btn, .article__body .bgt-btn, .job-contents__inner .bgt-btn {
  padding: 10px 20px;
}

.main-contents .bgt-grid4, .top__body .bgt-grid4, .article__body .bgt-grid4, .job-contents__inner .bgt-grid4 {
  width: 45%;
}

.main-contents table td, .main-contents table th, .top__body table td, .top__body table th, .article__body table td, .article__body table th, .job-contents__inner table td, .job-contents__inner table th {
  line-height: 24px;
}

.main-contents table td, .top__body table td, .article__body table td, .job-contents__inner table td {
  vertical-align: top;
}

.main-contents img, .top__body img, .article__body img, .job-contents__inner img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}

.main-contents h2, .top__body h2, .article__body h2, .job-contents__inner h2 {
  position: relative;
  margin-bottom: 1em;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.41;
  padding-bottom: 0.5em;
  border-bottom: 5px solid rgba(255, 0, 102, 0.1);
}

@media screen and (max-width: 768px) {
  .main-contents h2, .top__body h2, .article__body h2, .job-contents__inner h2 {
    font-size: 18px;
  }
}

.main-contents h2:before, .top__body h2:before, .article__body h2:before, .job-contents__inner h2:before {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 50px;
  height: 5px;
  background: #ff0066;
  content: "";
}

.main-contents h3, .top__body h3, .article__body h3, .job-contents__inner h3 {
  margin-bottom: 1em;
  padding-left: 12px;
  border-left: 3px solid #ff0066;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .main-contents h3, .top__body h3, .article__body h3, .job-contents__inner h3 {
    font-size: 16px;
  }
}

.main-contents h4, .top__body h4, .article__body h4, .job-contents__inner h4 {
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.41;
}

@media screen and (max-width: 768px) {
  .main-contents h4, .top__body h4, .article__body h4, .job-contents__inner h4 {
    font-size: 14px;
  }
}

.main-contents p, .top__body p, .article__body p, .job-contents__inner p {
  margin-bottom: 1.5em;
  font-size: 15px;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .main-contents p, .top__body p, .article__body p, .job-contents__inner p {
    font-size: 13px;
    line-height: 1.7;
  }
}

.main-contents p a, .top__body p a, .article__body p a, .job-contents__inner p a {
  color: #ff0066;
  text-decoration: underline;
}

.main-contents p a:hover, .top__body p a:hover, .article__body p a:hover, .job-contents__inner p a:hover {
  text-decoration: none;
}

.main-contents ul, .top__body ul, .article__body ul, .job-contents__inner ul {
  margin-bottom: 1.5em;
}

.main-contents ul li, .top__body ul li, .article__body ul li, .job-contents__inner ul li {
  position: relative;
  line-height: 2;
  margin-bottom: 0.5em;
  padding-left: 14px;
}

@media screen and (max-width: 768px) {
  .main-contents ul li, .top__body ul li, .article__body ul li, .job-contents__inner ul li {
    line-height: 1.7;
    font-size: 13px;
  }
}

.main-contents ul li:before, .top__body ul li:before, .article__body ul li:before, .job-contents__inner ul li:before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 0, 102, 0.3);
  content: "";
}

@media screen and (max-width: 768px) {
  .main-contents ul li:before, .top__body ul li:before, .article__body ul li:before, .job-contents__inner ul li:before {
    top: 7px;
  }
}

.main-contents ol, .top__body ol, .article__body ol, .job-contents__inner ol {
  margin-left: 20px;
  margin-bottom: 1.5em;
}

.main-contents ol li, .top__body ol li, .article__body ol li, .job-contents__inner ol li {
  position: relative;
  line-height: 2;
  margin-bottom: 0.5em;
  padding-left: 14px;
  list-style: decimal !important;
  list-style-position: inside;
}

@media screen and (max-width: 768px) {
  .main-contents ol li, .top__body ol li, .article__body ol li, .job-contents__inner ol li {
    line-height: 1.7;
    font-size: 13px;
  }
}

.main-contents table, .top__body table, .article__body table, .job-contents__inner table {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .main-contents table, .top__body table, .article__body table, .job-contents__inner table {
    float: none;
    font-size: 12px;
  }
}

.main-contents table th, .top__body table th, .article__body table th, .job-contents__inner table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .main-contents table th, .top__body table th, .article__body table th, .job-contents__inner table th {
    width: 100px;
  }
}

.main-contents table td, .top__body table td, .article__body table td, .job-contents__inner table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.job-contents h2 {
  margin-bottom: 1em;
  font-size: 26px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .job-contents h2 {
    font-size: 18px;
  }
}

.job-contents p strong {
  display: inline;
  background: linear-gradient(transparent 70%, #ff0066 70%);
}

.job-contents p a {
  text-decoration: underline;
}

.job-contents p a:hover {
  text-decoration: none;
}

.job-contents .bgb-text-image1,
.job-contents .bgb-text-image2,
.job-contents .bgb-wysiwyg {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .job-contents .bgt-image-container {
    margin-bottom: 1em;
  }
}

.job-contents table {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .job-contents table {
    float: none;
    font-size: 12px;
  }
}

.job-contents table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 230px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .job-contents table th {
    width: 100px;
  }
}

.job-contents table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

.job-contents .m-scroll:hover {
  text-decoration: none;
}

[data-bgb="rm-company"] .bgt-grid--first {
  float: left;
}

[data-bgb="rm-company"] .bgt-grid--first img {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-company"] .bgt-grid--first img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-company"] .bgt-grid--first {
    margin: 0;
    width: 100%;
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

[data-bgb="rm-company"] .bgt-grid--last {
  float: right;
}

[data-bgb="rm-company"] .bgt-grid12 {
  margin-bottom: 20px;
}

[data-bgb="rm-company"] table {
  width: 100%;
  border-top: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-company"] table {
    margin: 0;
    width: 100%;
    float: none;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

[data-bgb="rm-company"] table th {
  padding: 12px 15px;
  box-sizing: border-box;
  font-weight: bold;
  width: 120px;
  border-bottom: 1px solid #ddd;
  background-color: #f0f0f0;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-company"] table th {
    width: 100px;
  }
}

[data-bgb="rm-company"] table td {
  padding: 12px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
}

[data-bgb="rm-company"] table caption, [data-bgb="rm-company"] table th, [data-bgb="rm-company"] table td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

[data-bgb="rm-company"] .loop-slide ul li {
  padding-left: 0;
}

[data-bgb="rm-company"] .loop-slide ul li:before {
  width: 0;
  height: 0;
}

[data-bgb="rm-philosophy1"] {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  background-color: #FFF;
}

[data-bgb="rm-philosophy1"] .rm-body p {
  line-height: 2.375;
  margin-bottom: 30px;
  text-align: left;
}

@media screen and (max-width: 1000px) {
  [data-bgb="rm-philosophy1"] {
    width: 1000px;
    position: static;
    left: auto;
    right: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy1"] {
    width: 100%;
    position: relative;
  }
}

[data-bgb="rm-philosophy1"] {
  padding-top: 60px;
  font-weight: bold;
  padding-bottom: 60px;
}

[data-bgb="rm-philosophy1"] .rm-head1 {
  text-align: center;
}

[data-bgb="rm-philosophy1"] .rm-head1 h2 {
  position: relative;
  display: inline-block;
  font-size: 26px;
  color: #000;
  line-height: 1 !important;
  margin-bottom: 40px;
  padding-bottom: 0;
  border: 0;
  position: inherit;
  background: linear-gradient(transparent 70%, #ff0066 70%);
}

[data-bgb="rm-philosophy1"] .rm-head1 h2:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: none;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy1"] .rm-head1 h2 {
    margin-bottom: 15px;
    font-size: 18px;
  }
}

[data-bgb="rm-philosophy2"] {
  padding-top: 55px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] {
    padding-top: 40px;
  }
}

[data-bgb="rm-philosophy2"] .rm-body {
  position: relative;
  width: 50%;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .rm-body {
    padding-top: 0;
    width: 100%;
  }
}

[data-bgb="rm-philosophy2"] .bgt-title-h3-container {
  max-width: 600px;
}

[data-bgb="rm-philosophy2"] .bge-ckeditor {
  max-width: 600px;
}

[data-bgb="rm-philosophy2"] .right-image {
  overflow: hidden;
  margin: 0 auto 60px;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .right-image {
    text-align: center;
  }
}

[data-bgb="rm-philosophy2"] .right-image .rm-body {
  position: relative;
  left: 55px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .right-image .rm-body {
    left: 0;
  }
}

[data-bgb="rm-philosophy2"] .right-image .bgt-image-container {
  padding-left: 100px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .right-image .bgt-image-container {
    padding-left: 0;
  }
}

[data-bgb="rm-philosophy2"] .right-image .bgt-title-h3-container {
  margin: 0 0 15px auto;
}

[data-bgb="rm-philosophy2"] .right-image .bge-ckeditor {
  margin: 0 0 0 auto;
}

[data-bgb="rm-philosophy2"] .left-image {
  overflow: hidden;
  margin: 0 auto 60px;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .left-image {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
}

[data-bgb="rm-philosophy2"] .left-image .rm-body {
  position: relative;
  left: -55px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .left-image .rm-body {
    left: 0;
  }
}

[data-bgb="rm-philosophy2"] .left-image .bgt-image-container {
  padding-right: 100px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .left-image .bgt-image-container {
    padding: 0;
  }
}

[data-bgb="rm-philosophy2"] .left-image .bgt-title-h3-container {
  margin: 0 auto 15px 0;
}

[data-bgb="rm-philosophy2"] .left-image .bge-ckeditor {
  margin: 0 auto 0 0;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .left-image .bgt-grid--first {
    order: 2;
  }
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .left-image .bgt-grid--last {
    order: 1;
  }
}

[data-bgb="rm-philosophy2"] .bge-title-h3 {
  display: inline;
  background: linear-gradient(transparent 70%, #ff0066 70%);
  font-size: 26px;
  font-weight: bold;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bge-title-h3 {
    font-size: 18px;
  }
}

[data-bgb="rm-philosophy2"] .bge-ckeditor {
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bge-ckeditor {
    text-align: left;
  }
}

[data-bgb="rm-philosophy2"] .bge-ckeditor p {
  margin-bottom: 2em;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bge-ckeditor p {
    margin-bottom: 1em;
  }
}

[data-bgb="rm-philosophy2"] .bge-ckeditor a {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding-left: 30px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bge-ckeditor a {
    font-size: 14px;
    display: block;
    padding: 5px 0;
    width: 210px;
    margin: 30px auto 30px;
    border: 3px solid #000;
    text-align: center;
  }
  [data-bgb="rm-philosophy2"] .bge-ckeditor a:hover {
    text-decoration: none;
  }
}

[data-bgb="rm-philosophy2"] .bge-ckeditor a:before {
  position: absolute;
  top: -2px;
  left: 0;
  width: 24px;
  height: 24px;
  background: #ff0066;
  content: "";
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bge-ckeditor a:before {
    display: none;
  }
}

[data-bgb="rm-philosophy2"] .bge-ckeditor a:after {
  position: absolute;
  top: -6px;
  left: 10px;
  color: #fff;
  font-family: fontAwesome;
  content: '\f0da';
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bge-ckeditor a:after {
    display: none;
  }
}

[data-bgb="rm-philosophy2"] .bgt-grid--first {
  float: left;
  clear: both;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bgt-grid--first {
    float: none;
    padding-right: 0;
  }
}

[data-bgb="rm-philosophy2"] .bgt-grid--first img {
  max-width: inherit;
  width: 100%;
}

[data-bgb="rm-philosophy2"] .bgt-grid--last {
  float: right;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .bgt-grid--last {
    clear: both;
    float: none;
    padding-left: 0;
  }
}

[data-bgb="rm-philosophy2"] .bgt-grid--last .bgt-image-container {
  margin: 0 0 0 auto;
}

[data-bgb="rm-philosophy2"] .bgt-grid--last img {
  max-width: inherit;
  width: 100%;
}

[data-bgb="rm-philosophy2"] .rm-head1 h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 70px;
  letter-spacing: 0.05em;
  text-align: center;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 10px;
  border-bottom: none;
}

[data-bgb="rm-philosophy2"] .rm-head1 h2:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: none;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .rm-head1 h2 {
    font-size: 30px;
  }
}

[data-bgb="rm-philosophy2"] .rm-head1 p {
  margin-bottom: 40px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .rm-head1 p {
    margin-bottom: 30px;
    font-size: 12px;
  }
}

[data-bgb="rm-philosophy2"] .rm-head2 a {
  color: #ff0066;
  font-size: 30px;
}

[data-bgb="rm-philosophy2"] .rm-head2 h3 {
  margin-top: 60px;
  font-size: 30px;
  margin-bottom: 60px;
  font-weight: normal;
  text-align: left;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy2"] .rm-head2 h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 17px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
  }
}

[data-bgb="rm-philosophy2"] .bge-title-h3 {
  border-left: none !important;
}

[data-bgb="rm-philosophy3"] {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy3"] {
    padding-bottom: 60px;
  }
}

[data-bgb="rm-philosophy3"] .rm-head1 h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 70px;
  letter-spacing: 0.05em;
  text-align: center;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 10px;
  border-bottom: none;
}

[data-bgb="rm-philosophy3"] .rm-head1 h2:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: none;
  content: "";
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy3"] .rm-head1 h2 {
    font-size: 30px;
  }
}

[data-bgb="rm-philosophy3"] .rm-head1 p {
  margin-bottom: 40px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy3"] .rm-head1 p {
    margin-bottom: 20px;
    font-size: 12px;
  }
}

[data-bgb="rm-philosophy3"] .rm-head2 {
  text-align: center;
  margin-top: 40px;
  font-size: 26px;
  margin-bottom: 40px;
  padding-right: 0;
  line-height: 1.3;
  letter-spacing: 0.05em;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy3"] .rm-head2 {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 0;
    line-height: 1.5;
  }
}

[data-bgb="rm-philosophy3"] .rm-body {
  width: 500px;
  margin: 0 auto 50px;
  line-height: 1.85;
  text-align: left;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy3"] .rm-body {
    width: 100%;
    line-height: 1.8;
    margin: 0 0 20px;
  }
}

[data-bgb="rm-philosophy1"] .bge-ckeditor ul,
[data-bgb="rm-philosophy2"] .bge-ckeditor ul,
[data-bgb="rm-philosophy3"] .bge-ckeditor ul {
  margin-bottom: 1.5em;
}

[data-bgb="rm-philosophy1"] .bge-ckeditor ul li,
[data-bgb="rm-philosophy2"] .bge-ckeditor ul li,
[data-bgb="rm-philosophy3"] .bge-ckeditor ul li {
  position: relative;
  line-height: 2;
  margin-bottom: 0.5em;
  padding-left: 14px;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy1"] .bge-ckeditor ul li,
  [data-bgb="rm-philosophy2"] .bge-ckeditor ul li,
  [data-bgb="rm-philosophy3"] .bge-ckeditor ul li {
    line-height: 1.7;
    font-size: 13px;
  }
}

[data-bgb="rm-philosophy1"] .bge-ckeditor ul li:before,
[data-bgb="rm-philosophy2"] .bge-ckeditor ul li:before,
[data-bgb="rm-philosophy3"] .bge-ckeditor ul li:before {
  position: absolute;
  top: 10px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 0, 102, 0.3);
  content: "";
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy1"] .bge-ckeditor ul li:before,
  [data-bgb="rm-philosophy2"] .bge-ckeditor ul li:before,
  [data-bgb="rm-philosophy3"] .bge-ckeditor ul li:before {
    top: 7px;
  }
}

[data-bgb="rm-philosophy1"] .bge-ckeditor ol,
[data-bgb="rm-philosophy2"] .bge-ckeditor ol,
[data-bgb="rm-philosophy3"] .bge-ckeditor ol {
  margin-bottom: 1.5em;
}

[data-bgb="rm-philosophy1"] .bge-ckeditor ol li,
[data-bgb="rm-philosophy2"] .bge-ckeditor ol li,
[data-bgb="rm-philosophy3"] .bge-ckeditor ol li {
  position: relative;
  line-height: 2;
  margin-bottom: 0.5em;
  padding-left: 14px;
  list-style: decimal !important;
  list-style-position: inside;
}

@media screen and (max-width: 768px) {
  [data-bgb="rm-philosophy1"] .bge-ckeditor ol li,
  [data-bgb="rm-philosophy2"] .bge-ckeditor ol li,
  [data-bgb="rm-philosophy3"] .bge-ckeditor ol li {
    line-height: 1.7;
    font-size: 13px;
  }
}

.bgt-image[data-bge-hr="false"] .bgt-image__link .bgt-box__image-container img {
  width: 100%;
}

/**
 * ボタン
 *
 */
/* ボタンタイプ */
.bgt-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  padding: 12px 10px;
  border-width: 1px;
  border-style: solid;
  background: #fff;
  text-decoration: none;
  min-width: 200px;
  border-radius: 2px;
}

.bgt-btn:active, .bgt-btn:focus {
  -webkit-box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}

.bgt-btn--link,
[data-bgt-button-kind="link"] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--link:hover, .bgt-btn--link:active, .bgt-btn--link:focus,
[data-bgt-button-kind="link"] .bgt-btn:hover,
[data-bgt-button-kind="link"] .bgt-btn:active,
[data-bgt-button-kind="link"] .bgt-btn:focus {
  background: #e9e9e9;
}

.bgt-btn--em,
[data-bgt-button-kind="em"] .bgt-btn {
  font-weight: bold;
  color: #fff;
  border-color: #333;
  background: #333;
}

.bgt-btn--em:hover, .bgt-btn--em:active, .bgt-btn--em:focus,
[data-bgt-button-kind="em"] .bgt-btn:hover,
[data-bgt-button-kind="em"] .bgt-btn:active,
[data-bgt-button-kind="em"] .bgt-btn:focus {
  background: #555;
}

.bgt-btn--external,
[data-bgt-button-kind="external"] .bgt-btn {
  color: #333;
  border-color: #ccc;
  background: #fff;
}

.bgt-btn--external:hover, .bgt-btn--external:active, .bgt-btn--external:focus,
[data-bgt-button-kind="external"] .bgt-btn:hover,
[data-bgt-button-kind="external"] .bgt-btn:active,
[data-bgt-button-kind="external"] .bgt-btn:focus {
  background: #e9e9e9;
}

.bgt-btn--external::after,
[data-bgt-button-kind="external"] .bgt-btn::after {
  font-family: FontAwesome;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f08e';
  font-size: 1em;
  color: #999;
  vertical-align: middle;
}

.bgt-btn--back,
[data-bgt-button-kind="back"] .bgt-btn {
  color: #333;
  border-color: #ddd;
  background: #ddd;
}

.bgt-btn--back:hover, .bgt-btn--back:active, .bgt-btn--back:focus,
[data-bgt-button-kind="back"] .bgt-btn:hover,
[data-bgt-button-kind="back"] .bgt-btn:active,
[data-bgt-button-kind="back"] .bgt-btn:focus {
  background: #e9e9e9;
}

.bgt-btn--back span,
[data-bgt-button-kind="back"] .bgt-btn span {
  display: inline-block;
  vertical-align: middle;
}

.bgt-btn--back::before,
[data-bgt-button-kind="back"] .bgt-btn::before {
  font-family: FontAwesome;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f053';
  font-size: 1em;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
}

/* ダウンロードファイルタイプ */
.bgt-download-file__link {
  color: #333;
  text-align: left;
  font-size: 1.08em;
  font-weight: bold;
  display: inline-block;
  padding: 0.7em 1em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  line-height: 1;
  text-decoration: none;
}

.bgt-download-file__link:hover {
  background-color: #eee;
}

.bgt-download-file__link:active, .bgt-download-file__link:focus {
  -webkit-box-shadow: 0 0 5px 1px #0570c7;
  box-shadow: 0 0 5px 1px #0570c7;
  outline: none;
}

.bgt-link__size {
  color: #808080;
  font-size: 0.8em;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .bgt-google-maps-link {
    margin-top: 10px;
    padding: 1em;
    text-decoration: none;
    color: #333;
    border: solid 1px #999;
    border-radius: 3px;
    text-align: center;
  }
  .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bgt-google-maps-link::after {
    font-family: FontAwesome;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f08e';
    font-size: 1em;
    vertical-align: middle;
  }
}

[data-bgt='gallery'] .bgt-gallery {
  padding-top: 54%;
}

[data-bgt='gallery'] .bgt-gallery:hover [data-bgt='gallery'] .bgt-gallery-ctrl {
  opacity: 1;
}

[data-bgt='gallery'] .bgt-gallery__img img {
  -o-object-fit: cover;
  font-family: "object-fit:cover";
  object-fit: cover;
}

[data-bgt='gallery'] .bgt-gallery__caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.7em 1em;
  text-align: center;
}

[data-bgt='gallery'] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev, [data-bgt='gallery'] .bgt-gallery-ctrl__next {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev:hover, [data-bgt='gallery'] .bgt-gallery-ctrl__next:hover {
  opacity: 0.5;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev:focus, [data-bgt='gallery'] .bgt-gallery-ctrl__next:focus {
  outline: none;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev span, [data-bgt='gallery'] .bgt-gallery-ctrl__next span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev::after, [data-bgt='gallery'] .bgt-gallery-ctrl__next::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0;
}

[data-bgt='gallery'] .bgt-gallery-ctrl__prev {
  left: 20px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

[data-bgt='gallery'] .bgt-gallery-ctrl__next {
  right: 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

[data-bgt='gallery'] [data-gallery-marker='thumbs'] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt='gallery'] [data-gallery-marker='thumbs'] + .bgt-gallery-marker li {
  margin: 0 5px;
  width: 60px;
  height: 60px;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker {
  margin-top: 10px;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #999;
  border-radius: 100%;
}

[data-bgt='gallery'] [data-gallery-marker='dot'] + .bgt-gallery-marker li.current {
  background-color: #000;
}

/* デバイス依存汎用クラス */
.pc-only {
  display: block;
}

@media screen and (max-width: 768px) {
  .pc-only {
    display: none;
  }
}

.sp-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .sp-only {
    display: block;
  }
}

/**
 * 画像タイプのキャプション
 *
 */
.bgt-box__caption {
  text-align: left;
  font-weight: normal;
  padding: 3px;
  background: none;
  font-size: 0.8em;
}
