//-----------------------------
// @extend / @mixin専用ファイル
//-----------------------------
// テキスト非表示
//-----------------------------
%off {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}
/*clear fix*/
@mixin clearfix {
  &:after {
      display: block;
      visibility: hidden;
      clear: both;
      height: 0;
      content: '';
    }
}
@mixin anime300 {
    transition: all 300ms;
}
//-----------------------------
//img
//-----------------------------
@mixin rwd_img {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  margin: 0 auto;
}
//-----------------------------
//box-sizing
//-----------------------------
%bs {
  box-sizing: border-box;
}
@mixin bs {
    box-sizing: border-box;
}
//-----------------------------
// リンクテキストの下線スタイル
// a { @include txtLine(line);} //常に下線あり
// a { @include txtLine(hLine);} //hover時に下線あり
// a { @include txtLine(hNoLine);} //hover時に下線なし
// a { @include txtLine(noLine);} //常に下線無し
//-----------------------------
@mixin txtLine($style) {
  @if $style == line {
  text-decoration: underline;
    &:hover {
    text-decoration: underline;
    }
  }
  @else if $style == hLine {
  text-decoration: none;
    &:hover {
    text-decoration: underline;
    }
  }
  @else if $style == hNoLine {
  text-decoration: underline;
    &:hover {
    text-decoration: none;
    }
  }
  @else if $style == noLine {
  text-decoration: none;
    &:hover {
    text-decoration: none;
    }
  }
}
//-----------------------------
// 透明度
// div {@include opacity(0.7);}
//-----------------------------
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE);
}
// text-overflow
@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
// background(retina)
@mixin bg-base {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
}
//placeholder
@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content;
        -webkit-font-smoothing: antialiased;
        //line-height: 1.3
    }
    &:-moz-placeholder {
        @content;
        -moz-osx-font-smoothing: grayscale;
    }
    &::-moz-placeholder {
        @content;
        -moz-osx-font-smoothing: grayscale;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        @content;
    }
}
// 親の幅に関わらず画面幅一杯にする
@mixin full-browser-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin medium-range-full-width {
    width: 1000px;
    position: static;
    left: auto;
    right: auto;
    margin-left: 0;
    margin-right: 0;
}

//------------------
// media query
//------------------
$smallRange: 480px;
$mediumRange: 768px;
$largeRange: 1000px;

$mediumRangeForModal: 1110px;

@mixin mq($media) {
    @if $media == sp {
        @media screen and (max-width: #{$mediumRange}) {
            @content;
        }
    }
    @if $media == md {
        @media screen and (max-width: #{mediumRange}) {
            @content;
        }
    }
    @if $media == pc {
        @media screen and (max-width: #{$largeRange}) {
            @content;
        }
    }
    @if $media == spModal {
        @media screen and (max-width: #{$mediumRangeForModal}) {
            @content;
        }
    }
}
//@include gradient(#fff, #000, vertical);
@mixin gradient($start-color, $end-color, $orientation) {
 background: $start-color;
 @if $orientation == vertical
 {
 // vertical
 background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
 background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
 background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
 background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
 }
 @else if $orientation == horizontal
 {
 // horizontal
 background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
 }
 @else
 {
 // radial
 background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
 }
 }

 @mixin goldenRatiolize {

    @media screen and (max-width: #{$smallRange}) {
        min-width: 160px;
        min-height: calc(160px / #{$golden-ratio});
        width: 22.3vw;
        height: calc(22.3vw / #{$golden-ratio});
    }

    @media screen and (min-width: #{$smallRange}) and (max-width: #{$mediumRange}) {
        min-width: 220px;
        min-height: calc(220px / #{$golden-ratio});
        width: 43vw;
        height: calc(43vw / #{$golden-ratio});
    }

    @media screen and (min-width: #{$mediumRange}) {
        min-width: 320px;
        min-height: calc(320px / #{$golden-ratio});
    }
 }
